import React, { useEffect, useState } from "react";
import LoginContainer from "./components/LoginContainer";
import getSettingValue from "./helpers/getSettingValue";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faArrowLeft } from '@fortawesome/free-solid-svg-icons';
import searchParameters from "./helpers/searchParameters";
import getSettings from "./helpers/getSettings";
import { getClientId } from "./helpers/urlHelperFunctions";

const App = () => {

    const settingsDefault = {
        clientName : "eScreen123",
        waitListUrl : "",
        termsOfUseUrl : "",
        privacyPolicyUrl : "",
        clientServicesPhoneNumber : "",
        accountLockedErrorMessage : "",
        featuresSupported : {
            "ForgotUsername" : true,
            "ForgotPasswordEmailRecovery" : false,
            "ForgotPasswordChallengeQuestionsRecovery" : false,
            "ShowWaitList" : false,
        }
    }

    const [isCertUser, setIsCertUser] = useState(false);
    const [settings, setSettings] = useState(settingsDefault);

    useEffect(() => {

        const urlParams = new URLSearchParams(window.location.search);
        const returnUrl = urlParams.get(searchParameters.returnUrlSearchParameter); 
        const clientName = getClientId();

        getSettings(clientName,setSettings);

        if (!returnUrl) {
            return;
        }

        const acrValues = new URLSearchParams(returnUrl).get("acr_values");
        if (!acrValues) {
            return;
        }

        const splitAcrValues = acrValues.split('|');
        const certUserValue = 
        splitAcrValues.map(x => { 
            let y = x.split(':'); 
            return { [y[0]]:y[1] };})
            .filter(x => 
                x.certuser === "true").length > 0;
        setIsCertUser(certUserValue);
    }, []);

    return (
        <article>
            <header>
                {settings.ShowWaitList && 
                <a href={ settings.waitListUrl } id={settings.waitListUrl} style={{ display: isCertUser ? "block": "none" }}>
                    <FontAwesomeIcon icon={ faArrowLeft } />&nbsp;Waiting list 
                </a>
                }
            </header>
            <main>
                <LoginContainer settings={settings} />
            </main>
            <footer>
                <div className={ "leftFooter" }>
                    <a href={ settings.privacyPolicyUrl } target="_blank">Privacy Policy</a>
                </div>
                <div className={ "rightFooter" }>
                    <a href={settings.termsOfUseUrl} target="_blank">Terms of Use</a>
                </div>
                <div className={"rightBottomFooter"}>
                   
                    <div id="consent-banner"></div>
                    <div id="teconsent"></div>
                </div>

            </footer>
        </article>
    );
};

export default App;
