import React, { useEffect, useState } from "react";
import LogoHeader from "./LogoHeader";
import LogoFooter from "./LogoFooter";
import stepPages from "../helpers/steps";
import ErrorContainer from "../controls/ErrorContainer";

const AccountLocked = ( props ) => {
    const [errorDescription, setErrorDescription] = useState(null);

    useEffect(() => {

    },[props.isActive]);

    return (
        <div className="modal-content">
            <div>
                <div>
                    <LogoHeader clientName={props.settings.clientName} />
                </div>
            </div>
            <div>
                <p className="bold">Account Locked</p>
            </div>
            <ErrorContainer errorDescription={errorDescription} />
            <div>
                <div>
                    <p style={{whiteSpace:"pre-line"}}>{props.settings.accountLockedErrorMessage}</p>
                    
                </div>
            </div>
            <div>
                <div>
                    <button
                        id={"alButtonReturnToLogin"}
                        type="button"
                        className="button full-width"
                        onClick={() => {
                                //F186085
                                props.goToStep(stepPages.ForgotPassword)
                                let secretQuestionsButton = document.getElementById("fpButtonResetViaSecretQuestions")
                                if (secretQuestionsButton !== null) {
                                    secretQuestionsButton.disabled = true
                                    secretQuestionsButton.style.cursor = 'default'
                                    secretQuestionsButton.style.backgroundColor = 'rgba(0,79,113,.45)'
                                }
                            }
                        }
                    >
                        Reset Password
                    </button>
                </div>
            </div>
            <div>
                <LogoFooter />
            </div>
        </div>
    );
};

export default AccountLocked;