import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faAngleLeft } from '@fortawesome/free-solid-svg-icons';

const BackButton = (props) => {
    
    return (
        <div className="left" >
            <button type="button" className="btn-wrapper abt-color--blue-medium" onClick={() => {
                props.goToStep(props.step)

                let secretQuestionsButton = document.getElementById("fpButtonResetViaSecretQuestions")

                //F186085
                if (secretQuestionsButton !== null) {
                    secretQuestionsButton.disabled = false
                    secretQuestionsButton.style.cursor = 'pointer'
                    secretQuestionsButton.style.backgroundColor = 'rgb(0, 79, 113)'
                }                                

            }} id={props.myId}>
                <FontAwesomeIcon icon={faAngleLeft} className="fa-2x" />
            </button>
        </div>
    );
};

export default BackButton;